<template>
  <div class="container-xl row" v-if="!$store.getters.isForbidden">
    <div class="col-lg-8 mb-2">
      <div class="card card-body">
        <div class="row">
          <el-descriptions class="margin-top col-lg-12" title="Refund Information" :column="1" border>
            <el-descriptions-item label-class-name="my-label">
              <template slot="label">
                <i class="el-icon-date"></i>
                Request Date
              </template>
              {{ refund_detail.created_at_str }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-user"></i>
                User Name
              </template>
              {{ user_data.full_name }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-phone"></i>
                Phone Number
              </template>
              {{ user_data.phone_number }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-message"></i>
                Email
              </template>
              {{ user_data.email }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-s-order"></i>
                Issue
              </template>
              {{ refund_detail.issue }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-folder-checked"></i>
                Approval Status
              </template>
              <span class="tag" :class="refund_detail.approval_status_color">{{ refund_detail.approval_status_str }}</span>
            </el-descriptions-item>
            <el-descriptions-item v-if="refund_detail.approval_status == 'rejected'">
              <template slot="label">
                <i class="el-icon-document"></i>
                Reject Reason
              </template>
              {{ refund_detail.reject_reason}}
            </el-descriptions-item>
            <el-descriptions-item v-if="refund_detail.refund_status">
              <template slot="label">
                <i class="el-icon-coin"></i>
                Refund Status
              </template>
              <span class="tag" :class="refund_detail.refund_status_color">{{ refund_detail.refund_status_str }}</span>
            </el-descriptions-item>
          </el-descriptions>
          <div class="col-lg-12">
            <div class="el-descriptions__header">
              <div class="el-descriptions__title">Attachment</div>
            </div>
            <el-carousel :autoplay="false" class="margin-top">
              <el-carousel-item v-for="(item, index) in refund_detail.refund_attachments" :key="index">
                <!-- <h3 class="medium">{{ item.url }}</h3> -->
                <el-image :src="item.url" style="height: 300px" fit="contain">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-warning-outline"></i>&nbsp;
                    Failed load attachment
                  </div>
                </el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="row mt-2" v-if="refund_detail.approval_status == 'waiting_approval'">
          <div class="col-lg-12">
            <div class="float-right">
              <el-button @click="handlerApproveBtn" class="mr-2" type="primary" size="small"><font-awesome-icon icon="check"/> Approve Request</el-button>
              <el-button @click="handlerRejectBtn" class="mr-2 ml-2" type="danger" size="small"><font-awesome-icon icon="times"/> Reject Request</el-button>
            </div>
          </div>
        </div>
        <template v-if="isBrandCom">
          <div class="row mt-2" v-if="refund_detail.approval_status == 'approved' && !refund_detail.refund_status">
            <div class="col-lg-12">
              <div class="float-right">
                <el-button @click="handlerBtnRequestDisbursement" class="mr-2" type="primary" size="small"><font-awesome-icon icon="money-bill-wave"/> Request Refund Disbursement</el-button>
              </div>
            </div>
          </div>
        </template>
        <template v-if="isFinance">
          <div class="row mt-2" v-if="refund_detail.approval_status == 'approved' && refund_detail.refund_status == 'waiting_approval'">
            <div class="col-lg-12">
              <div class="float-right">
                <el-button @click="handlerExecuteDisburse" class="mr-2" type="primary" size="small"><font-awesome-icon icon="money-bill-wave"/> Execute Disbursement</el-button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="col-lg-4 mb-2" style="max-height: 800px; overflow-y:scroll">
      <div class="card">
        <div class="card-header border-0 justify-content-between">
          <div class="card-title">Approval History</div>
        </div>
        <div class="card-body">
          <el-empty description="No approval history" v-if="!refund_detail.approval_history.length" :image="emptyStateImage"></el-empty>
          <el-timeline v-if="refund_detail.approval_history.length">
            <el-timeline-item v-for="item in refund_detail.approval_history" :timestamp="item.created_at_simple"
              placement="top"
              :color="item.dot_color"
              :type="item.dot_color"
              :key="item.id">
              <el-card>
                <h4 :style="{ color: item.dot_color }">{{ item.action_str }}</h4>
                <p><strong>{{ item.user.name }}</strong> {{ item.action_str.toLowerCase() }} at {{ item.created_at_str }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="card card-body">
        <el-descriptions class="margin-top" title="Order Information" :column="2" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-date"></i>
              Transaction Date
            </template>
            {{ order_data.created_at_str }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-document"></i>
              Invoice Number
            </template>
            {{ order_data?.invoice_number || "-" }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              User Name
            </template>
            {{ user_data.full_name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone"></i>
              Phone Number
            </template>
            {{ user_data.phone_number }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-message"></i>
              Email
            </template>
            {{ user_data.email }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-wallet"></i>
              Payment Method
            </template>
            {{ order_data.pm.payment_name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-money"></i>
              Amount
            </template>
            {{ order_data.amount_str }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-c-scale-to-original"></i>
              Virtual Account Number
            </template>
            {{ order_data.virtual_account_number || '-' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-discount"></i>
              Promotion Used
            </template>
            {{ order_data.promo_used_name }}
          </el-descriptions-item>
        </el-descriptions>
        <table class="table table-vcenter w-full mt-2">
          <thead>
            <tr>
              <th width="25%">Product Name</th>
              <th>Type</th>
              <th>Destination Number</th>
              <th>Vendor</th>
              <th>Qty</th>
              <th>Base Price</th>
              <th>Sell Price</th>
              <th>Ref. Number</th>
              <th>Status</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in refund_detail.details" :key="i">
              <td>{{ item.product.name }}</td>
              <td>{{ item.source_type_str }}</td>
              <td>{{ item.hp || "-" }}</td>
              <td>{{ item.vendor ? item.vendor.vendor_name : "-" }}</td>
              <td>{{ item.product.quantity }}</td>
              <td>{{ item.product.base_price }}</td>
              <td>{{ item.product.sell_price }}</td>
              <td>{{ item.ref_vendor }}</td>
              <td>
                <el-popover v-if="item.order_detail_status == 0 && item.order_detail_message"
                  placement="left"
                  width="200"
                  trigger="hover"
                  :content="item.order_detail_message">
                    <span slot="reference" class="tag" :class="item.status_color">{{ item.status_str }}</span>
                </el-popover>
                <span v-else class="tag" :class="item.status_color">{{ item.status_str }}</span>
              </td>
              <td>{{ item.product.total_price }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="9"><i>Admin Fee</i></td>
              <td>{{ order_data.admin_fee_str }}</td>
            </tr>
            <tr v-for="(item, i) in order_data.detail_discount" :key="i">
              <td colspan="9"><i>{{ item.promo_name }}</i></td>
              <td><i>-{{ item.promo_discount_str }}</i></td>
            </tr>
            <tr style="background-color: #f4f6fa; font-weight: bold;">
              <td colspan="9">Subtotal</td>
              <td>{{ order_data.amount_str }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { upperFirst } from 'lodash';
import emptyStateImage from '@/assets/images/undraw/empty-approval-history.svg';
import {
  DETAIL_REFUND_REQUEST, APPROVAL_REFUND_REQUEST, DISBURSE_REFUND_REQUEST,
} from '../../../store/modules/refund_request';

export default {
  name: 'RefundRequestDetail',
  metaInfo: {
    title: 'Refund Request',
  },
  data() {
    return {
      emptyStateImage,
      invoice_number_str: '',
      user_data: {},
      order_data: {
        pm: {},
      },
      refund_detail: {
        approval_history: [],
      },
      approval_status: {
        waiting_approval: {
          text: 'Waiting Approval',
          color: 'tag-warning',
        },
        approved: {
          text: 'Approved',
          color: 'tag-success',
        },
        rejected: {
          text: 'Rejected',
          color: 'tag-danger',
        },
      },
      refund_status: {
        waiting_approval: {
          text: 'Waiting Approval',
          color: 'tag-warning',
        },
        process: {
          text: 'On Process',
          color: 'tag-info',
        },
        done: {
          text: 'Completed',
          color: 'tag-success',
        },
      },
      total_rows: 0,
      loader: null,
      loaderStack: 0,
    };
  },
  async mounted() {
    await this.loadDetail();
  },
  computed: {
    currentRole() {
      return this.$store.getters.current_role;
    },
    isBrandCom() {
      return this.currentRole.role.id === process.env.VUE_APP_BRANDCOM_ROLE_ID || this.currentRole.page_name === '*';
    },
    isFinance() {
      return this.currentRole.role.id === process.env.VUE_APP_FINANCE_ROLE_ID || this.currentRole.page_name === '*';
    },
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    handlerRejectBtn() {
      this.$prompt('You will reject this request. Please input reason why you reject this request.', 'Confirmation', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        inputPlaceholder: 'Please input reason why you reject this request.',
        inputType: 'textarea',
        inputValidator: (value) => {
          if (!value) {
            return 'You should input reason why you reject this request.';
          }
          if (value.length < 20) {
            return 'Your reason must have more than 20 character';
          }
          return true;
        },
        inputErrorMessage: 'Reject reason should more than 20 character',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            const reason = instance.inputValue;
            await this.$store.dispatch(APPROVAL_REFUND_REQUEST, {
              id: this.$route.params.id,
              data: {
                action: 'reject',
                reason,
              },
            });
            instance.confirmButtonLoading = false;
          }
          done();
        },
      }).then(() => {
        this.loadDetail();
      }).catch(() => {});
    },
    async loadDetail() {
      this.showLoader();
      await this.$store.dispatch(DETAIL_REFUND_REQUEST, {
        id: this.$route.params.id,
      }).then((data) => {
        const { order, user } = data;
        this.invoice_number_str = order.invoice_number ? `#${order.invoice_number}` : "-";
        order.amount_str = `Rp. ${order.total_transaction.toLocaleString()}`;
        order.created_at_str = moment(order.created_at).format('DD MMM YYYY, HH:mm');
        order.admin_fee_str = `Rp. ${order.admin_fee.toLocaleString()}`;
        order.promo_used_name = order.detail_discount[0] ? order.detail_discount[0].promo_name : '-';

        data.created_at_str = moment(data.created_at).format('DD MMM YYYY, HH:mm');
        data.approval_status_str = this.approval_status[data.approval_status].text;
        data.approval_status_color = this.approval_status[data.approval_status].color;
        if (data.refund_status) {
          data.refund_status_str = this.refund_status[data.refund_status].text;
          data.refund_status_color = this.refund_status[data.refund_status].color;
        }
        data.approval_history = data.approval_history.reverse().map((v) => {
          v.action_str = 'Unknown action';
          v.dot_color = '#ff8f15';
          if (v.action === 'update') {
            v.action_str = 'Update refund request';
            v.dot_color = '#f1c40f';
          } else if (v.action === 'reject') {
            v.action_str = 'Reject refund request';
            v.dot_color = '#cd201f';
          } else if (v.action === 'approve') {
            v.action_str = 'Approve refund request';
            v.dot_color = '#5eba00';
          } else if (v.action === 'done') {
            v.action_str = 'Process Refund Disbursement';
          }
          v.role_name = v.user.role.name;
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          v.created_at_simple = moment(v.created_at).format('DD MMM YYYY');
          return v;
        });

        data.details = data.details.map((v) => {
          const {
            detail_product, quantity, sell_price, total_price, v_price, base_price, transaction_qri, amount, insurance_order,
          } = v;
          v.source_type_str = upperFirst(v.source_type);
          if (v.source_type === 'v_price') {
            v.source_type_str = 'PPOB';
          } else if (v.source_type === 'special_program') {
            v.source_type_str = 'Spesial Program';
          } else if (v.source_type === 'lms') {
            v.source_type_str = 'Academy';
          }
          v.product = {};
          if (v.source_type === 'voucher') {
            v.product = {
              name: detail_product.voucher_name,
              base_price: detail_product.voucher_base_price ? `Rp. ${detail_product.voucher_base_price.toLocaleString()}` : detail_product.voucher_base_price,
              sell_price: sell_price ? `Rp. ${sell_price.toLocaleString()}` : sell_price,
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString()}` : total_price,
            };
          } else if (v.source_type === 'v_price') {
            v.product = {
              name: v_price.v_price_type ? [v_price.v_price_type, v_price.nominal].join(' ') : v_price.product_code,
              base_price: base_price ? `Rp. ${base_price.toLocaleString()}` : base_price,
              sell_price: sell_price ? `Rp. ${sell_price.toLocaleString()}` : sell_price,
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString()}` : total_price,
            };
            if (v_price.vendor_id.toString() === '8') {
              if (v.detail_product.provider && v.detail_product.nominal) {
                v.product.name = [v.detail_product.provider, v.detail_product.nominal].join(' ');
              } else {
                v.product.name = v_price.nominal;
              }
            }
          } else if (v.source_type === 'academic') {
            v.product = {
              name: detail_product.academic_name,
              base_price: base_price ? `Rp. ${base_price.toLocaleString()}` : base_price,
              sell_price: sell_price ? `Rp. ${sell_price.toLocaleString()}` : sell_price,
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString()}` : total_price,
            };
          } else if (v.source_type === 'qris') {
            v.product = {
              name: transaction_qri.transaction_qris_name,
              base_price: '-',
              sell_price: '-',
              quantity: 1,
              total_price: amount ? `Rp. ${amount.toLocaleString()}` : amount,
            };
          } else if (v.source_type === 'insurance') {
            v.product = {
              name: insurance_order.insurance_name,
              base_price: base_price ? `Rp. ${base_price.toLocaleString()}` : base_price,
              sell_price: sell_price ? `Rp. ${sell_price.toLocaleString()}` : sell_price,
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString()}` : total_price,
            };
          } else if (v.source_type === 'special_program') {
            v.product = {
              name: detail_product.productName,
              base_price: base_price ? `Rp. ${base_price.toLocaleString()}` : base_price,
              sell_price: sell_price ? `Rp. ${sell_price.toLocaleString()}` : sell_price,
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString()}` : total_price,
            };
          } else if (v.source_type === 'lms') {
            v.product = {
              name: detail_product.name,
              base_price,
              base_price_str: base_price ? `Rp. ${base_price.toLocaleString()}` : '-',
              sell_price,
              sell_price_str: sell_price ? `Rp. ${sell_price.toLocaleString()}` : '-',
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString()}` : '-',
            };
          }
          v.status_str = 'Failed';
          v.status_color = 'tag-danger';

          if (v.order_detail_status === 1) {
            // v.status_str = 'Process';
            // v.status_color = 'tag-info';
            // if (order.status === 1) {
            v.status_str = 'Waiting payment';
            v.status_color = 'tag-warning';
            // }
          } else if (v.order_detail_status === 2) {
            v.status_str = 'Success';
            v.status_color = 'tag-success';
          } else if (v.order_detail_status === 3) {
            v.status_str = 'Refund';
            v.status_color = 'tag-teal';
          }
          return v;
        });

        this.refund_detail = data;
        this.order_data = order;
        this.user_data = user;
      });
      this.hideLoader();
    },
    handlerApproveBtn() {
      this.$confirm('You will approve this refund request. Continue?', 'Confirmation', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(APPROVAL_REFUND_REQUEST, {
              id: this.$route.params.id,
              data: {
                action: 'approve',
              },
            });
            instance.confirmButtonLoading = false;
          }
          done();
        },
      }).then(() => {
        this.loadDetail();
      }).catch(() => {});
    },
    handlerBtnRequestDisbursement() {
      this.$confirm('This refund will be process by Finance Team after you submit this request. Continue?', 'Confirmation', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(DISBURSE_REFUND_REQUEST, {
              id: this.$route.params.id,
              data: {
                action: 'approve',
              },
            });
            instance.confirmButtonLoading = false;
          }
          done();
        },
      }).then(() => {
        this.loadDetail();
      }).catch(() => {});
    },
    handlerExecuteDisburse() {
      this.$confirm('Execute refund process for this request. Continue?', 'Confirmation', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(DISBURSE_REFUND_REQUEST, {
              id: this.$route.params.id,
              data: {
                action: 'done',
              },
            });
            instance.confirmButtonLoading = false;
          }
          done();
        },
      }).then(() => {
        this.loadDetail();
      }).catch(() => {});
    },
  },
};
</script>
<style>
.my-label {
  width: 170px;
}
</style>